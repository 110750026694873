import React, { Component } from 'react';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react/src/main';
import { Link, withRouter } from 'react-router-dom';
import { SmartButtonStub } from 'ui/smart-buttons'


class SeatedEventReservationBlock extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedSeats: [],
      workspaceKey: '',
      seatsioEventId: '',
      loading: true, // Set loading to true initially
      error: null,
    };
  }

  componentDidMount() {
    const { eventId, token } = this.getParamsFromURL();

    if (eventId && token) {
      this.fetchEventDetails(eventId, token);
    } else {
      this.setState({ 
        error: 'Missing event ID or token in URL.',
        loading: false 
      });
    }
  }

  getParamsFromURL = () => {
    const hash = window.location.hash.substring(1); // Remove the '#' character
    const parts = hash.split('/');

    // Validate URL structure
    if (parts.length < 5 || parts[1] !== 'event' ) {
      return { eventId: null, token: null };
    }

    const eventId = parts[2];
    const token = parts[4];

    return { eventId, token };
  };

  fetchEventDetails = async (eventId, token) => {
    try {
      const response = await Backend.get('/events/fetch_seatsio_deatils', { event_id: eventId, token: token });
      const { event_id, workspace_key } = response;
      if (event_id && workspace_key) {
        this.setState({ 
          seatsioEventId: event_id, 
          workspaceKey: workspace_key,
          loading: false 
        });
      } else {
        this.setState({ 
          error: 'Invalid event data received.',
          loading: false 
        });
      }
    } catch (error) {
      console.error('Error fetching event details:', error);
      this.setState({ 
        error: 'Failed to load event details.', 
        loading: false 
      });
    }
  };

  confirmSeats = () => {
    const { eventId, token } = this.getParamsFromURL();
    const { selectedSeats } = this.state;

    this.props.history.push(`/event/${eventId}/reserve/${token}/seats:[${selectedSeats}]`);
  };

  cancelReservation = () => {
    const { eventId, token } = this.getParamsFromURL();

    this.setState({ selectedSeats: [] });
    // Update the URL to include cancel and event ID
    this.props.history.push(`/event/${eventId}/cancel/${token}/event:${eventId}`);
  };

  onSeatSelected = (seat) => {
    this.setState((prevState) => ({
      selectedSeats: [...prevState.selectedSeats, seat.id]
    }));
  };

  onSeatDeselected = (seat) => {
    this.setState((prevState) => ({
      selectedSeats: prevState.selectedSeats.filter(selectedSeat => selectedSeat !== seat.id)
    }));
  };

  render() {
    const { workspaceKey, seatsioEventId, loading, error, selectedSeats } = this.state;

    if (loading) {
      return <div className="loading">Loading seating chart...</div>;
    }

    if (error) {
      return <div className="error">{error}</div>;
    }

    return (
      <div className="SeatedEventReservation-form">
        <h3 className="SeatedEventReservation-heading">Reservation Details</h3>
        <div className="seating-chart-container">
          <SeatsioSeatingChart
            workspaceKey={workspaceKey}
            event={seatsioEventId}
            region="na"
            onObjectSelected={this.onSeatSelected}
            onObjectDeselected={this.onSeatDeselected}
            showFullScreenButton={false}
          />
        </div>
        <div className="SelectedSeats">
          <h4>Selected Seats:</h4>
          {selectedSeats.length > 0 ? (
            <ul>
              {selectedSeats.map(seat => <li key={seat}>{seat}</li>)}
            </ul>
          ) : (
            <p>No seats selected.</p>
          )}
        </div>
        <div className="EventReservation-action">
          <SmartButtonStub
            className='mb-3'
            text="Confirm Reservation" 
            click={this.confirmSeats} 
            disabled={selectedSeats.length === 0} 
          />
          <SmartButtonStub
            className='mb-3'
            text="Back" 
            click={this.cancelReservation} 
          />
        </div>
      </div>
    );
  }
}

export default withRouter(SeatedEventReservationBlock);
